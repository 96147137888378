import React, { PropsWithChildren } from 'react'
import { HorizontalItemScroller, HorizontalItemScrollerProps } from '@/molecules/HorizontalItemScroller'
import { MediaCard, PlaceholderMediaCard } from '@/molecules/MediaCard'
import { MediaCardProps } from '@/molecules/MediaCard/MediaCard'
import { useGuildUser } from '@/services/GuildUserService'
import { Media } from '@/utils/EpisodeUtil'

export interface KeyedMediaCardProps extends MediaCardProps {
  key: string
  guid: string
}

interface HorizontalMediaScrollerProps extends PropsWithChildren<HorizontalItemScrollerProps> {
  medias?: Media[]
  onMediaClick?: (media: Media) => void
  scrollerClassName?: string
}

export const HorizontalMediaScroller: React.FC<HorizontalMediaScrollerProps> = ({
  children,
  medias,
  onMediaClick,
  scrollerClassName,
  ...rest
}) => {
  const { guildRoles } = useGuildUser()
  const userHasEarlyAccess = Boolean(guildRoles.hasEarlyAccess)
  const hasMediaCards = Boolean(medias?.length)
  const hasChildren = React.Children.count(children) > 0

  return (
    <HorizontalItemScroller scrollerClassName={scrollerClassName} {...rest}>
      {hasMediaCards &&
        !hasChildren &&
        medias?.map?.((media, i) => {
          return (
            <MediaCard
              className="mr-4"
              {...media}
              key={media.guid}
              imgPriority={i <= 4}
              onClick={() => onMediaClick?.(media)}
              includeHoverScale={false}
              userHasEarlyAccess={userHasEarlyAccess}
            />
          )
        })}
      {!hasMediaCards && hasChildren && children}
      {!hasMediaCards &&
        !hasChildren &&
        Array(5)
          .fill(0)
          .map((v, i) => (
            <PlaceholderMediaCard className="mr-4" borderRadiusClassName="rounded-lg" hideTextPlaceholders key={i} />
          ))}
    </HorizontalItemScroller>
  )
}
